import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import echarts from 'echarts'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/common/style/index.css'
import '@/common/element/element-ui-reset.scss'
// import { Dialog, Button, Form,FormItem, Select, Option,Icon,Message,
//   Notification } from 'element-ui';
import borderBox from './components/borderBox'

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

//百度地图vue
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'yQzckVgCOMVBxWtg2NbAvxclZ0jM9yrB'
})

Vue.use(dataV)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.component('borderBox', borderBox)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

<template>
  <div class="wrapper-layout">
    <ScaleScreen :width="1920" :height="1080" class="scale-wrap">
      <Lheader></Lheader>
      <Lmain></Lmain>
      <!-- <Lfooter></Lfooter> -->
    </ScaleScreen>
  </div>
</template>

<script>
import Lheader from './header.vue'
import Lmain from './main.vue'
import Lfooter from './footer.vue'
import ScaleScreen from '@/components/scale-screen/scale-screen.vue'

export default {
  components: { Lheader, Lmain, Lfooter, ScaleScreen },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.wrapper-layout {
  width: 100%;
  height: 100%;
  position: relative;
  background: rgb(2, 2, 3);
  .scale-wrap {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
  }
}
</style>

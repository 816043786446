
import store from '@/store'
import layout from '@/views/layout'

// 设备
export const equipments = [
  // 总揽
  {
    path: '/equipment/general',
    name: 'General',
    flag: 'devicelist',
    component: () => import('@/views/equipment/general/index.vue')
  },
  // 实时检测
  {
    path: '/equipment/monitor',
    name: 'Monitor',
    flag: 'deviceonline',
    component: () => import('@/views/equipment/monitor/index.vue')
  },
  // 安全性能评估
  {
    path: '/equipment/safetyPerformance',
    name: 'SafetyPerformance',
    flag: 'devicediagnose',
    component: () =>
      import('@/views/equipment/safetyPerformance/index.vue')
  },
  // 寿命预测
  {
    path: '/equipment/lifeForecast',
    name: 'LifeForecast',
    flag: 'deviceforecast',
    component: () => import('@/views/equipment/lifeForecast/index.vue')
  },
  // 维修路径规划
  {
    path: '/equipment/maintain',
    name: 'Maintain',
    flag: 'devicemaintain',
    component: () => import('@/views/equipment/maintain/index.vue')
  },
  // 维保策略
  {
    path: '/equipment/strategy',
    name: 'Strategy',
    flag: 'devicesafety',
    component: () => import('@/views/equipment/strategy/index.vue')
  }
]

export const setMenu = (rightList = [])=>{
  // 整理生成路由
  let route = {
    path: '/',
    name: 'Layout',
    component: layout,
    redirect: '',
    children: []
  }
  let children = route.children
  rightList.forEach(item => {
    // 首页
    if (item.menuName === '首页') {
      let home = {
        // name: item.children[0].path || 'Home',
        // path: '/' + (item.children[0].path || 'home'),
        name: 'Home',
        path: '/home',
        component: () => import('@/views/home/index.vue')
      }
      children.push(home)
      route.redirect = home.path
    }
    // 设备
    if (item.menuName === '健康管理') {
      let equipment = {
        // name: item.children[0].path || 'Equipment',
        // path: '/' + (item.children[0].path || 'equipment'),
        name: 'Equipment',
        path: '/equipment',
        component: () => import('@/views/equipment/index.vue'),
        redirect: '',
        children: []
      }
      // 设备内页
      let Echildren = equipment.children
      if (Array.isArray(item.children) && item.children.length) {
        item.children.forEach(el => {
          equipments.forEach(equip => {
            if (el.path === equip.flag) {
              // Echildren.push({
                // name: el.path,
                // path: equipment.path + '/' + el.path,
                // component: equip.component
              // })
              Echildren.push(equip)
            }
          })
        })
      }
      // 设置重定向为第一个子元素的路由
      if (equipment.children.length) {
        equipment.redirect = equipment.children[0].path
      }
      children.push(equipment)
    }
  })

  // 将新生成的路由存进store
  store.commit('SET_ROUTER', route)
  return route
}
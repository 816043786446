<template>
  <dv-border-box-1 style="width: 100%; height: 100%">
    <div class="box-title">
      <div class="title-bg">
        <div class="missAngle">
          <img :src="src" />
          <slot name="title" />
        </div>
      </div>
    </div>
    <slot name="box-container" />
  </dv-border-box-1>
</template>

<script>
export default {
  props: ['src']
}
</script>

<style scoped lang="scss">
::v-deep .border-box-content {
  // width: 100%;
  // height: 100%;
  padding: 0 13px;
}
.box-title {
  padding-top: 27px;
  padding-left: 14px;
  padding-right: 14px;
  .title-bg {
    background: linear-gradient(
      90deg,
      rgb(10, 77, 113) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .missAngle {
    background: linear-gradient(135deg, rgba(0, 14, 49) 10px, transparent 0);

    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 22px;
    display: flex;
    align-items: center;
  }

  img {
    width: 28px;
    height: 28px;
    vertical-align: middle;
    margin-right: 16px;
    display: inline-block;
  }
  .title,
  ::v-deep .border-title {
    font-family: PingFangSC;
    font-weight: 600;
    font-size: 18px;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(30, 208, 248, 1) 100%
    ); // 背景线性渐变
    color: #ffffff; // 兜底颜色，防止文字裁剪不生效
    background-clip: text;
    -webkit-background-clip: text; // 背景被裁减为文字的前景色
    -webkit-text-fill-color: transparent; // 文字填充为透明，优先级比color高。
  }
}
</style>

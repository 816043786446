<template>
  <div class="wrapper-layout-footer">
    <div class="footer-inner">
      <p>浙ICP备2022023855号</p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrapper-layout-footer {
  width: 100%;
  .footer-inner {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: url('../../assets/img/footer.png') no-repeat center center;
    background-size: cover;
    position: absolute;
    bottom: 0;
    display: block;
    font-family: PingFangSC;
    color: #63e2ff;
    font-size: 16px;
    margin: auto;
    text-align: center;
  }
}
</style>

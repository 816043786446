import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import { setMenu } from '@/utils/nemu'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/login1.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/login/register.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const whiteList = ['/login', '/register']
router.beforeEach((to, from, next) => {
  // TODO 清除本地缓存的rightList
  const rightList = JSON.parse(localStorage.getItem('rightList'))
  if (rightList?.length) {
    if (store.state.router.children?.length) {
      next()
    } else {
      const route = setMenu(rightList)
      router.addRoute(route)
      next({ ...to })
    }
  } else {
    if (
      whiteList.some((el) => {
        return el === to.path
      })
    ) {
      next()
    } else {
      next('/login')
    }
  }
})
export default router

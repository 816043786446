<template>
  <div class="wrapper-layout-main">
    <router-view></router-view>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrapper-layout-main {
  width: 100%;
  height: 100%;
  margin-top: 105px;
}
</style>

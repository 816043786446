<template>
  <div class="wrapper-layout-header">
    <div class="header-inner">
      <div class="header-tab" v-if="activeFlag">
        <img
          src="@/assets/img/false-shebei.png"
          alt=""
          @click="handleGoShebei"
        />
        <img src="@/assets/img/true-home.png" alt="" />
      </div>
      <div class="header-tab" v-else>
        <img src="@/assets/img/true-shebei.png" alt="" />
        <img
          src="@/assets/img/false-home.png"
          alt=""
          @click="handleGoHome"
        />
      </div>
      <div class="header-title">
        <span>电梯安全防控云平台</span>
      </div>
      <div class="header-time">{{ dateYear }} {{ dateDay }}</div>
      <div class="header-user">
        <div class="user-avatar">
          <el-avatar
            size="medium"
            src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
          ></el-avatar>
        </div>
        <div class="user-info">
          <span>欢迎您</span>
          <span>{{ userName }}</span>
        </div>
      </div>
      <div class="header-icon">
        <img src="@/assets/icon/full.png" @click="screen" alt="" />
        <img src="@/assets/icon/people.png" alt="" />
        <img src="@/assets/icon/logout.png" @click="Logout" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '@/utils/index.js'
import screenfull from 'screenfull'

export default {
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      activeFlag: true,
      userName: ''
    }
  },
  created() {
    this.activeFlag = this.$route.path === '/home'
    this.timeFn()

    // 获取用户名
    this.userName = this.$store.state.username || ''
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH : mm : ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
      }, 1000)
    },
    handleGoHome() {
      console.log('去首页')
      this.activeFlag = true
      this.$router.push('/home')
    },
    handleGoShebei() {
      console.log('去设备')
      this.activeFlag = false
      this.$router.push('/equipment/general')
    },
    screen() {
      // 判断是否支持
      if (!screenfull.isEnabled) {
        this.$message({
          message: '不支持全屏',
          type: 'warning'
        })
        return false
      }

      screenfull.toggle()
    },
    // 退出登录
    Logout() {
      localStorage.clear()
      this.$router.push('/login')
      window.location.reload()
    }
  },
  beforeDestroy() {
    clearInterval(this.timing)
    // this.$store.commit('REMOVE_ROUTER')
  }
}
</script>

<style lang="scss" scoped>
.wrapper-layout-header {
  width: 100%;
  height: 105px;
  background: url('../../assets/img/header.png') no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0;
  .header-inner {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    padding: 32px 45px 19px 210px;
    position: relative;
  }
  .header-tab {
    height: 48px;
    position: relative;
    z-index: 10;
    top: 6px;
    img {
      width: 260px;
      height: 100%;
      cursor: pointer;
    }
    & img:nth-last-of-type(1) {
      position: absolute;
      left: 0;
    }
    & img:nth-last-of-type(2) {
      position: absolute;
      left: 200px;
    }
  }
  .header-title {
    width: 100%;
    height: 48px;
    position: absolute;
    text-align: center;
    background-size: cover;
    color: transparent;
    left: 0;
    span {
      height: 48px;
      font-family: PingFangSC;
      letter-spacing: 5px;
      font-style: normal;
      text-align: center;
      font-size: 38px;
      font-weight: 900;
      letter-spacing: 6px;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(30, 208, 248, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .header-time {
    /* 2022-11-21 12:00:30 */
    height: 20px;
    font-family: PingFangSC;
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    right: 430px;
    top: 57px;
  }

  .header-user {
    display: flex;
    position: absolute;
    right: 220px;
    top: 47px;

    .user-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .user-info {
      margin-left: 15px;
      span {
        display: block;
      }
      & span:nth-of-type(1) {
        height: 20px;
        font-family: PingFangSC;
        color: #d0d0d0;
        font-weight: 400;
        font-size: 14px;
      }
      & span:nth-of-type(2) {
        height: 20px;
        font-family: PingFangSC;
        color: #fff;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .header-icon {
    width: 130px;
    position: absolute;
    right: 45px;
    top: 57px;
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
      width: 36px;
      height: 36px;
    }
  }
}
</style>

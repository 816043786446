import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    equipment: '',
    tab: '',
    signal: '',
    // 登录相关
    rightList: [], // 接口返回的路由数据
    router: {}, // 路由
    uid: localStorage.getItem('uid'),
    username: localStorage.getItem('username'),
    deviceList: JSON.parse(localStorage.getItem('deviceList') || '[]'),
    currentDevice: localStorage.getItem('currentDevice'),
    // 电流信号数据
    dataRawXX: [],
    dataRawYY: [],
    dataWeightXX: [],
    dataWeightYY: []
  },
  mutations: {
    SET_EQUIPMENT(state, id) {
      state.equipment = id
    },
    REMOVE_EQUIPMENT(state) {
      state.equipment = ''
    },
    SET_TAB(state, tab) {
      state.tab = tab
    },
    REMOVE_TAB(state) {
      state.tab = ''
    },
    SET_SIGNAL(state, signal) {
      state.signal = signal
    },
    REMOVE_SIGNAL(state) {
      state.signal = ''
    },
    SET_ROUTER(state, router) {
      state.router = router
    },
    REMOVE_ROUTER(state) {
      state.router = {}
    },
    setRightList(state, data) {
      state.rightList = data
      localStorage.setItem('rightList', JSON.stringify(data))
    },
    setUid(state, data) {
      state.uid = data
      localStorage.setItem('uid', data)
    },
    setUsername(state, data) {
      state.username = data
      localStorage.setItem('username', data)
    },
    setDeviceList(state, data) {
      state.deviceList = data
      localStorage.setItem('deviceList', JSON.stringify(data))
    },
    setCurrentDevice(state, data) {
      state.currentDevice = data
      localStorage.setItem('currentDevice', data)
    },
    setDataRawXX(state, data) {
      state.dataRawXX = data
    },
    setDataRawYY(state, data) {
      state.dataRawYY = data
    },
    setDataWeightXX(state, data) {
      state.dataWeightXX = data
    },
    setDataWeightYY(state, data) {
      state.dataWeightYY = data
    }
  },
  actions: {},
  modules: {}
})
